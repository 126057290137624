.custom-select__multi-value {
  background: transparent !important;
  border: 1px solid #80bdff !important;
}
.custom-select--is-disabled{
  background: gray;
  border-radius: 6px;
}
.custom-select__input {
  color: #ffffff;
}
.custom-select__multi-value__label {
  color: #ffffff !important;
}
.custom-select__single-value {
    color: #ffffff !important;
  }
.custom-select__multi-value__remove {
  // background-color: #FFBDAD;
  color: #de350b;
  &:hover {
    background: transparent !important;
  }
}
