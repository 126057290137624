/* Forms */

.form-group {
  margin-bottom: 1rem !important;
}
.input-group-append,
.input-group-prepend {
  color: $input-placeholder-color;
  width: auto;
  border: none;

  .input-group-text {
    border-color: $border-color;
    padding: $input-padding-y 0.75rem;
    color: $input-placeholder-color;
    background: none;
  }
}
.set-eye-pos {
  position: absolute;
  top: 0;
  right: 20px;
}
.form-control {
  margin-bottom: 0.5rem !important;
  border: 1px solid $border-color;
  height: calc(2.25rem + 2px);
  font-weight: normal;
  font-size: $input-font-size;
  padding: 0.625rem 0.6875rem;
  background-color: transparent; // $input-bg
  border-radius: 2px;
  color: #ffffff;
  &:disabled {
    opacity: 0.5;
    background-color: transparent;
  }
  &:focus {
    // border: 1px solid rgba(0, 144, 231, 0.5);
    color: #ffffff; // added
    background-color: transparent; //$input-bg;
  }
  &.form-control-lg {
    padding: 0.94rem 0.75rem;
    height: calc(2.875rem + 2px);
  }

  &.form-control-sm {
    padding: 0.5rem 0.75rem;
    height: calc(1.8125rem + 2px);
  }
  &::placeholder {
    color: $text-muted;
    font-size: $input-placeholder-font-size;
  }
  &.is-invalid {
    background-image: none;
  }
}
.invalid-feedback {
  display: block;
}
#search-bar-0 {
  &::placeholder {
    color: #ffffff;
  }
}
.navbar {
  .form-control {
    width: 75%;
    border-radius: 6px;
    &:focus {
      border: none;
    }
  }
}

select {
  &.form-control {
    padding: 0.4375rem 0.75rem;
    border: 1;
    outline: 1px solid #322f2f;
    background-position: right calc(0.375em + 1.1875rem) center !important;
    color: lighten($white, 50%);
    &:focus {
      outline: 1px solid $border-color;
    }
    @each $color, $value in $theme-colors {
      &.border-#{$color} {
        outline: 1px solid $value;
        &:focus {
          outline: 1px solid $value;
        }
      }
    }
  }
}
select option {
  background: #ffffff;
  color: black; //optional color #13151b
}
.form-group {
  label {
    font-size: $default-font-size;
    line-height: 1;
    vertical-align: top;
  }
  &.has-danger {
    .form-control {
      border-color: theme-color(danger);
    }
  }
  .file-upload-default {
    visibility: hidden;
    position: absolute;
  }
  .file-upload-info {
    background: transparent;
  }
}
.amount {
  text-align: end;
}

.form-check {
  min-height: 18px;
  padding-left: 1.25rem;
}

div {
  &.tagsinput {
    background: transparent;
  }
}

.custom-file {
  .visibility-hidden {
    visibility: hidden;
  }
  .custom-file-label {
    background: $input-bg;
    border: 1px solid $border-color;
    height: calc(2.25rem + 2px);
    font-weight: normal;
    font-size: 0.875rem;
    padding: 0.625rem 0.6875rem;
    border-radius: 2px;
    &:after {
      background-color: theme-color(primary);
      height: auto;
      @extend .btn-primary;
    }
  }
}

.col-form-label {
  font-size: 0.855rem;
}

.holiday__form {
  .holiday-check {
    .form-check-input {
      width: 20px;
      height: 20px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.btn-supplier, .btn-customer {
  width: 28px;
  border-radius: 50%;
  margin-top: 5px;
  margin-bottom: 2px;
  padding: 5px 0px;
  font-size: 1rem;
}

.btn-close {
  width: 34px;
  border-radius: 50%;
  margin-top: 5px;
  margin-bottom: 2px;
  padding: 5px 0px;
  font-size: 1rem;
}

.pos-form, .po-form {
  .slider {
    &__body {
    }
    &__card {
      margin-right: 0 !important;
    }
    &__card-body {
      padding: 1.75rem 0 !important;
      margin-right: 0 !important;
    }
    &__item {
      &-btn {
        min-height: 144px;
        // &:hover{
        //   border: 2px solid theme-color(primary);
        // }
      }
    }
  }

  .slick-slider {
    .slick-arrow.slick-prev {
      width: 30px;
      height: 30px;
      position: absolute;
      top: -40px;
      left: 82%;
      &::before {
        font-size: 1rem;
      }
    }
    .slick-arrow.slick-next {
      width: 30px;
      height: 30px;
      position: absolute;
      top: -40px;
      &::before {
        font-size: 1rem;
      }
    }

    .slick-slide {
      div {
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
  
  .slick-track{
    margin-right: unset;
    margin-left: unset;
  }

  .products {
    &__wrapper {
    }
  }

  .product {
    &__item {
      width: 100%;
      border-radius: 10px;
      border: none;
      &:hover {
        .product__img {
          &::before {
            content: "";
            width: 20px;
            height: 20px;
            background: url(../../images//white-heavy-check-mark.svg);
            position: absolute;
            top: 13px;
            right: 8px;
            opacity: 1;
            z-index: 2;
          }
          img {
            transform: scale(1);
          }
        }
      }
    }
    &__img img {
      transform: scale(0.8);
      transition: all 0.1s ease-in-out;
    }
    &__item.selected {
      // background: theme-color(primary);
      // border: 0.5px solid theme-color(primary);
      .product__img {
        &::before {
          content: "";
          width: 20px;
          height: 20px;
          background: url(../../images//white-heavy-check-mark.svg);
          position: absolute;
          top: 13px;
          right: 8px;
          opacity: 1;
          z-index: 2;
        }
        img {
          transform: scale(1);
        }
      }
    }
    &__card {
      height: 100%;
    }
    &__card-header {
      padding: 0.8rem 0.5rem;
    }

    &__img {
      background: theme-color(secondary);
      border-radius: 10px;
    }
  }

  // added products section
  .added-products {
    &__card-body {
      max-height: 35vh;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 3px;
        height: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: theme-color(muted);
        border-radius: 10px;
      }

      /* Handle on hover*/
      &::-webkit-scrollbar-thumb:hover {
        background: theme-color(muted);
      }

      .action-btn{
        font-size: 1.2em;
      }
    }
  }
}
